export const renderStatusStyles = (type: string) => {
  switch (type) {
    case 'active':
      return {
        color: 'rgba(0, 152, 22, 1)',
      }
    case 'awaiting':
      return {
        color: 'rgba(255, 92, 0, 1)',
      }
    case 'sold':
      return {
        color: 'rgba(0, 127, 244, 1)',
      }
    case 'closed':
      return {
        color: 'rgba(245, 39, 26, 1)',
      }
    case 'reposted':
    case 'scheduled':
      return {
        color: 'rgba(34, 34, 34, 0.72)',
      }
    default:
      return {
        color: '#222222',
      }
  }
}
