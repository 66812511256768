import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Collapse,
  Divider,
  Grid,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material'
import { addWatchlist, removeWatchlist } from 'api/requests/auction'
import { AttributesRow } from 'components/AttributesRow'
import { AuctionStatus } from 'components/AuctionStatus'
import { BidLabel } from 'components/BidLabel'
import { CornerLabel } from 'components/CornerLabel'
import { Label } from 'components/Label'
import { ModalCustom } from 'components/ModalCustom'
import { PriceStatus } from 'components/PriceStatus'
import { TooltipForEllipsis } from 'components/TooltipForEllipsis'
import { WatchlistIconButton } from 'components/WatchlistIconButton'
import { FILE_SERVER_URL } from 'constants/urls'
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  sub,
} from 'date-fns'
import { useUserContext } from 'hooks'
import { ClearIcon } from 'icons'
import { capitalize } from 'lodash'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import PlaceholderImage from 'public/images/image-placeholder.png'
import React, { FC, MouseEvent, useMemo, useState } from 'react'
import { UserRoles } from 'types/enums'
import { getPackagesTitle } from 'utils/auction'
import { formatCurrency } from 'utils/format'

import { Props } from './AuctionCardVertical.types'

export const AuctionCardVertical: FC<Props> = ({
  auctionId,
  status,
  address,
  image,
  title,
  manufacturer,
  condition,
  typeAttributes,
  isCurrentAuction = false,
  packages,
  totalBids,
  myMaxBid,
  isMyBidHighest,
  startingBidPrice,
  currentBidPrice,
  winningBidPrice,
  itemCount,
  totalWeight,
  isFavourite = false,
  onRemoveWatchlistFinish,
  endDate,
  loading,
  hasReservePrice,
  isReservePriceMet,
  alwaysDisplayBid,
}) => {
  const router = useRouter()

  const { user } = useUserContext()
  const isAuthenticated = Boolean(user)
  const isBuyer = Boolean(
    user?.roles.some(role => role.code === UserRoles.Buyer)
  )

  const restAttrs = typeAttributes ? [...typeAttributes] : []
  const splicedAttrs = restAttrs.splice(0, 2)
  const totalPackages = useMemo(() => getPackagesTitle(packages), [packages])

  const [selectedWatchlist, setSelectedWatchlist] = useState(isFavourite)

  const [open, setOpen] = useState(false)
  const [openRemoveModal, setOpenRemoveModal] = useState(false)

  const [authRequiredModalOpen, setAuthRequiredModalOpen] = useState(false)

  const [isMouseOver, setIsMouseOver] = useState(false)

  const daysLeft = differenceInDays(endDate, new Date())
  const hoursLeft = differenceInHours(
    sub(endDate, { days: daysLeft }),
    new Date()
  )
  const minutesLeft = differenceInMinutes(
    sub(endDate, { days: daysLeft, hours: hoursLeft }),
    new Date()
  )

  const bidToShow =
    status === 'active' && !totalBids
      ? startingBidPrice
      : status === 'sold'
      ? winningBidPrice
      : currentBidPrice

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault()
    setOpen(!open)
    return false
  }

  const handleWatchlistChange = async (event: MouseEvent<HTMLElement>) => {
    event.preventDefault()

    if (!isAuthenticated) {
      setAuthRequiredModalOpen(true)
      return
    }

    if (auctionId) {
      try {
        if (selectedWatchlist) {
          setOpenRemoveModal(true)
        } else {
          await addWatchlist({ auctionId })
          setSelectedWatchlist(!selectedWatchlist)
        }
      } catch (e) {
        console.error(e)
      }
    }
  }

  const handleAcceptDeleting = async () => {
    if (auctionId) {
      try {
        await removeWatchlist(auctionId)
        setSelectedWatchlist(!selectedWatchlist)
        onRemoveWatchlistFinish?.()
      } catch (e) {
        console.error(e)
      } finally {
        setOpenRemoveModal(false)
      }
    }
  }

  return loading ? (
    <Card
      sx={{
        borderRadius: '16px',
        p: '13px 16px 4px',
        boxShadow:
          '0px 0px 2px rgba(34, 34, 34, 0.16), 0px 1px 2px rgba(34, 34, 34, 0.08)',
      }}
    >
      <Skeleton
        variant={'rectangular'}
        sx={{
          height: '216px',
          borderRadius: '8px',
        }}
      />
      <CardContent
        sx={{
          p: '0',
          mt: '12px',
        }}
      >
        <Skeleton variant={'text'} width={'100%'} />
        <Skeleton variant={'text'} width={'60%'} />
        <Stack direction={'row'} spacing={'4px'} sx={{ mb: '18px' }}>
          {splicedAttrs?.map(attr => (
            <Skeleton key={attr}>
              <Label key={attr} title={attr} />
            </Skeleton>
          ))}
        </Stack>
        <Divider />
        <Skeleton
          variant={'text'}
          width={'100%'}
          sx={{
            my: '12px',
          }}
        />
        {alwaysDisplayBid && (
          <Skeleton
            variant={'rectangular'}
            sx={{
              width: '100%',
              height: '120px',
              borderRadius: '8px',
              mb: '18px',
            }}
          />
        )}
        <Box>
          <Divider />
          <Stack
            spacing={'8px'}
            sx={{
              mt: '18px',
            }}
          >
            <Skeleton variant={'text'} width={'100%'} />
            <Skeleton variant={'text'} width={'100%'} />
            <Skeleton variant={'text'} width={'100%'} />
            <Skeleton variant={'text'} width={'100%'} />
          </Stack>
        </Box>
      </CardContent>
    </Card>
  ) : (
    <Link href={`/auctions/${auctionId}`}>
      <Card
        sx={{
          position:
            isCurrentAuction && status === 'active' ? 'relative' : 'static',
          borderRadius: '16px',
          p: '13px 16px 4px',
          cursor: 'pointer',
          boxShadow:
            '0px 0px 2px rgba(34, 34, 34, 0.16), 0px 1px 2px rgba(34, 34, 34, 0.08)',
          ':hover': {
            boxShadow:
              '0px 0px 2px rgba(34, 34, 34, 0.1), 0px 4px 9px rgba(34, 34, 34, 0.06)',
          },
          overflow: 'visible',
          mb:
            isCurrentAuction && isMouseOver && status === 'active'
              ? '139px'
              : '0',
        }}
        onMouseOver={() => setIsMouseOver(true)}
        onMouseLeave={() => setIsMouseOver(false)}
      >
        <Box
          sx={{
            position: 'relative',
            height: '216px',
            borderRadius: '8px',
            overflow: 'hidden',
          }}
        >
          {image ? (
            <CardMedia
              component='img'
              height='216'
              image={`${FILE_SERVER_URL}${image}`}
              alt='lot'
            />
          ) : (
            <Image src={PlaceholderImage} alt='' />
          )}
          {isCurrentAuction && <CornerLabel title={'Current Auction'} />}
          {(!isAuthenticated || isBuyer) && (
            <WatchlistIconButton
              selected={selectedWatchlist}
              onChange={handleWatchlistChange}
            />
          )}
        </Box>

        <CardContent
          sx={{
            p: '0',
            mt: '12px',
          }}
        >
          <TooltipForEllipsis
            title={title}
            sx={{
              fontWeight: 600,
              lineHeight: '24px',
              marginBottom: '12px',
              height: '48px',
              whiteSpace: 'initial',
              display: '-webkit-box',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical',
              wordBreak: 'break-word',
            }}
          />
          <Stack
            direction={'row'}
            spacing={'4px'}
            sx={{ mb: '18px', position: 'relative' }}
          >
            {splicedAttrs?.map(attr => (
              <Label key={attr} title={attr} />
            ))}
            {!!restAttrs.length && (
              <>
                <Button
                  onClick={handleClick}
                  aria-label='show more'
                  sx={{
                    borderRadius: '11px',
                    height: '20px',
                    width: 'fit-content',
                    bgcolor: 'rgba(255, 184, 0, 0.24)',
                    p: '0 8px',
                    fontWeight: 600,
                    fontSize: '13px',
                    color: 'text.primary',
                    minWidth: 'auto',
                    ':hover': {
                      bgcolor: 'rgba(255, 184, 0, 0.24)',
                    },
                  }}
                >
                  {open ? (
                    <ClearIcon
                      width={18}
                      height={18}
                      viewBox='0 0 24 24'
                      color='rgba(34, 34, 34, 1)'
                    />
                  ) : (
                    `+${restAttrs.length}`
                  )}
                </Button>
                <Collapse in={open} timeout='auto'>
                  <Grid
                    container
                    spacing={'4px'}
                    sx={{
                      flexWrap: 'wrap',
                      position: 'absolute',
                      top: '100%',
                      right: 0,
                      backgroundColor: 'white',
                      mt: 0,
                      borderRadius: '11px',
                      zIndex: '1',
                      pb: '4px',
                    }}
                  >
                    {restAttrs?.map(attr => (
                      <Grid key={attr} item>
                        <Label title={attr} />
                      </Grid>
                    ))}
                  </Grid>
                </Collapse>
              </>
            )}
          </Stack>

          <Divider />

          <Stack
            direction={'row'}
            sx={{
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              m: '12px 0',
            }}
          >
            <Stack
              direction={'row'}
              sx={{
                alignItems: 'baseline',
              }}
            >
              <TooltipForEllipsis
                title={`${formatCurrency(Number(bidToShow), {
                  withPrefix: true,
                  minimumFractionDigits: 0,
                })}`}
                variant={'h2'}
                sx={{
                  maxWidth: '136px',
                }}
              />

              <Typography
                variant={'body2'}
                sx={{
                  ml: '4px',
                }}
              >
                {status === 'sold'
                  ? 'winning bid'
                  : ['awaiting', 'closed'].includes(status)
                  ? 'last bid'
                  : `${totalBids} bids`}
              </Typography>
            </Stack>
            {status === 'active' ? (
              <Typography variant={'body2'}>
                ends in{' '}
                <Typography component={'span'} fontWeight={600}>
                  {`${daysLeft > 0 ? `${daysLeft}d ` : ''}
                  ${hoursLeft >= 0 ? hoursLeft : 0}h ${
                    minutesLeft >= 0 ? minutesLeft : 0
                  }m`}
                </Typography>
              </Typography>
            ) : (
              <AuctionStatus title={status} fontSize={'14px'} />
            )}
          </Stack>

          <Box
            sx={{
              borderRadius: '0 0 16px 16px',
              background: 'white',
              display: 'block',
              boxShadow:
                isCurrentAuction && status === 'active' && isMouseOver
                  ? '0px 0px 2px rgba(34, 34, 34, 0.1), 0px 4px 9px rgba(34, 34, 34, 0.06)'
                  : 'none',
              position:
                isCurrentAuction && status === 'active' && isMouseOver
                  ? 'absolute'
                  : 'static',
              width: '100%',
              p:
                isCurrentAuction && status === 'active' && isMouseOver
                  ? '0 16px 28px'
                  : 0,
              left: 0,
              zIndex: 1,
              ':before': {
                content: `''`,
                position: 'absolute',
                left: 0,
                top: '-4px',
                background: isMouseOver ? 'white' : 'transparent',
                width: '100%',
                height: '4px',
              },
            }}
          >
            {((isCurrentAuction && status === 'active' && isMouseOver) ||
              alwaysDisplayBid) && (
              <Stack
                sx={{
                  alignItems: 'center',
                  borderRadius: '12px',
                  p: '13px',
                  mb: '16px',
                  bgcolor: 'primary.100',
                  width: '100%',
                }}
              >
                <BidLabel isHighest={isMyBidHighest} />
                <Stack
                  direction={'row'}
                  sx={{
                    alignItems: 'baseline',
                    mb: '11px',
                  }}
                >
                  <Typography variant={'h2'}>
                    {formatCurrency(myMaxBid, {
                      withPrefix: true,
                      minimumFractionDigits: 0,
                    })}
                  </Typography>
                  <Typography
                    variant={'body2'}
                    sx={{
                      ml: '4px',
                    }}
                  >
                    your max bid
                  </Typography>
                </Stack>

                <PriceStatus
                  hasReservePrice={hasReservePrice}
                  isReservePriceMet={isReservePriceMet}
                />
              </Stack>
            )}
            <Divider />

            <Stack
              spacing={'8px'}
              sx={{
                mt: '18px',
              }}
            >
              <AttributesRow
                items={[capitalize(condition)]}
                label='Condition'
              />
              <AttributesRow
                items={[
                  `${itemCount} Items`,
                  totalWeight ? `${totalWeight} lbs` : '',
                  totalPackages,
                ]}
                label='Lot size'
              />
              {manufacturer && (
                <AttributesRow
                  withComma
                  items={manufacturer}
                  label='Mfr.'
                  isItemsEllipsis
                />
              )}
              <AttributesRow
                items={[address]}
                label='Location'
                isItemsEllipsis
              />
            </Stack>
          </Box>
        </CardContent>

        <ModalCustom
          open={openRemoveModal}
          onCloseClick={() => setOpenRemoveModal(false)}
          onAcceptClick={handleAcceptDeleting}
          acceptText='Delete'
        >
          <>Do you really want to remove the auction from the watchlist?</>
        </ModalCustom>

        <ModalCustom
          open={authRequiredModalOpen}
          onCloseClick={() => setAuthRequiredModalOpen(false)}
          onAcceptClick={async () => {
            await router.push('/account/login')
            setAuthRequiredModalOpen(false)
          }}
          acceptText='Log in'
        >
          <>Authorization is required</>
        </ModalCustom>
      </Card>
    </Link>
  )
}
