import { Chip } from '@mui/material'
import { FC } from 'react'

import { Props } from './Label.types'

export const Label: FC<Props> = ({ title, margin }) => {
  return (
    <Chip
      label={title}
      sx={{
        borderRadius: '11px',
        fontSize: '13px',
        height: '20px',
        width: 'fit-content',
        textTransform: 'capitalize',
        bgcolor: 'rgba(255, 184, 0, 0.24)',
        margin,
        '.MuiChip-label': {
          p: '0 8px',
          fontWeight: 600,
          fontSize: '13px',
        },
      }}
    />
  )
}
