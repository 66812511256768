import { Box, CircularProgress, Stack, Typography } from '@mui/material'
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  sub,
} from 'date-fns'
import { FC, useEffect, useState } from 'react'
import { AuctionStatus } from 'types/enums'

import { CircularProps, Props } from './AuctionTimer.types'

const CircularProgressWithLabel: FC<CircularProps> = ({
  days,
  hours,
  minutes,
  seconds,
  isLarge,
  ...props
}) => {
  return (
    <Stack direction={'row'} spacing={isLarge ? '20px' : '12px'}>
      {days ? (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
          <CircularProgress
            size={isLarge ? '92px' : '56px'}
            thickness={1.5}
            variant='determinate'
            value={days && Math.round((100 / days) * days)}
            {...props}
          />
          <CircularProgress
            size={isLarge ? '92px' : '56px'}
            thickness={1.5}
            variant='determinate'
            value={100}
            sx={{ color: 'grey.200', position: 'absolute' }}
            {...props}
          />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography
              sx={{
                fontSize: isLarge ? '18px' : '14px',
                fontWeight: 600,
                lineHeight: '24px',
              }}
            >{`${Math.round(days)}d`}</Typography>
          </Box>
        </Box>
      ) : null}
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress
          size={isLarge ? '92px' : '56px'}
          thickness={1.5}
          variant='determinate'
          value={hours && Math.round((100 / hours) * hours)}
          {...props}
        />
        <CircularProgress
          size={isLarge ? '92px' : '56px'}
          thickness={1.5}
          variant='determinate'
          value={100}
          sx={{ color: 'grey.200', position: 'absolute' }}
          {...props}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: isLarge ? '18px' : '14px',
              fontWeight: 600,
              lineHeight: '24px',
            }}
          >{`${Math.round(hours)}h`}</Typography>
        </Box>
      </Box>
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress
          size={isLarge ? '92px' : '56px'}
          thickness={1.5}
          variant='determinate'
          value={Math.round((100 / 60) * minutes)}
          {...props}
        />
        <CircularProgress
          size={isLarge ? '92px' : '56px'}
          thickness={1.5}
          variant='determinate'
          value={100}
          sx={{ color: 'grey.200', position: 'absolute' }}
          {...props}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: isLarge ? '18px' : '14px',
              fontWeight: 600,
              lineHeight: '24px',
            }}
          >{`${Math.round(minutes)}m`}</Typography>
        </Box>
      </Box>
      {days ? null : (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
          <CircularProgress
            size={isLarge ? '92px' : '56px'}
            thickness={1.5}
            variant='determinate'
            value={Math.round((100 / 60) * seconds)}
            {...props}
          />
          <CircularProgress
            size={isLarge ? '92px' : '56px'}
            thickness={1.5}
            variant='determinate'
            value={100}
            sx={{ color: 'grey.200', position: 'absolute' }}
            {...props}
          />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography
              sx={{
                fontSize: isLarge ? '18px' : '14px',
                fontWeight: 600,
                lineHeight: '24px',
              }}
            >{`${Math.round(seconds)}s`}</Typography>
          </Box>
        </Box>
      )}
    </Stack>
  )
}

export const AuctionTimer: FC<Props> = ({
  auctionStatus,
  endDate,
  isLarge,
}) => {
  const [timerId, setTimerId] = useState<ReturnType<typeof setInterval> | null>(
    null
  )

  const [days, setDays] = useState(0)
  const [hours, setHours] = useState(0)
  const [minutes, setMinutes] = useState(0)
  const [seconds, setSeconds] = useState(0)

  const intervalFunction = () => {
    const daysLeft = differenceInDays(endDate, new Date())
    const hoursLeft = differenceInHours(
      sub(endDate, { days: daysLeft }),
      new Date()
    )
    const minutesLeft = differenceInMinutes(
      sub(endDate, { days: daysLeft, hours: hoursLeft }),
      new Date()
    )
    const secondsLeft = differenceInSeconds(
      sub(endDate, { days: daysLeft, hours: hoursLeft, minutes: minutesLeft }),
      new Date()
    )

    if (hoursLeft < 0 || minutesLeft < 0 || secondsLeft < 0) {
      if (timerId) {
        clearInterval(timerId)
      }
      return
    }

    setDays(daysLeft)
    setHours(hoursLeft)
    setMinutes(minutesLeft)
    setSeconds(secondsLeft)
  }

  useEffect(() => {
    if (
      new Date().getTime() > endDate.getTime() ||
      auctionStatus !== AuctionStatus.Active
    ) {
      return
    }

    intervalFunction()
    if (timerId) {
      clearInterval(timerId)
      return
    }
    setTimerId(setInterval(intervalFunction, 1000))
    return () => {
      if (timerId) {
        clearInterval(timerId)
      }
    }
  }, [endDate, timerId])

  useEffect(() => {
    if (auctionStatus !== AuctionStatus.Active) {
      setDays(0)
      setHours(0)
      setMinutes(0)
      setSeconds(0)

      if (timerId) {
        clearInterval(timerId)
      }
    }
  }, [auctionStatus, timerId])

  return (
    <CircularProgressWithLabel
      days={days >= 0 ? days : 0}
      hours={hours >= 0 ? hours : 0}
      minutes={minutes >= 0 ? minutes : 0}
      seconds={seconds >= 0 ? seconds : 0}
      isLarge={isLarge}
    />
  )
}
