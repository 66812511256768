import ToggleButton from '@mui/material/ToggleButton'
import { AddWatchlistIcon, InWatchlistIcon } from 'icons'
import { FC } from 'react'

import { Props } from './WatchlistIconButton.types'

export const WatchlistIconButton: FC<Props> = ({ selected, onChange }) => {
  return (
    <ToggleButton
      value='add'
      selected={selected}
      sx={{
        position: 'absolute',
        bottom: 12,
        right: 12,
        width: '32px',
        height: '32px',
        p: '5px',
        borderRadius: '50%',
        backgroundColor: 'white',
        '&:hover': {
          backgroundColor: 'white',
          borderColor: 'grey.300',
        },
        '&.Mui-selected': {
          backgroundColor: 'white',
          '&:hover': {
            backgroundColor: 'white',
          },
        },
      }}
      onChange={onChange}
    >
      {selected ? (
        <InWatchlistIcon width={24} height={24} color='rgba(0, 153, 112, 1)' />
      ) : (
        <AddWatchlistIcon width={24} height={24} color='rgba(34, 34, 34, 1)' />
      )}
    </ToggleButton>
  )
}
