import { Box, Skeleton, Stack, Typography } from '@mui/material'
import { InfoButton } from 'components/InfoButton'
import { TooltipForEllipsis } from 'components/TooltipForEllipsis'
import React, { FC } from 'react'

import { Props } from './AttributesRow.types'

export const AttributesRow: FC<Props> = ({
  items,
  label,
  isItemsEllipsis = false,
  isLarge = false,
  withComma = false,
  withInfo = false,
  infoTitle,
  infoText,
  loading = false,
  valueLoading = false,
  capitalizeItems = true,
}) => {
  const ellipsisStyles = {
    maxWidth: {
      xs: isLarge ? '250px' : '180px',
      md: isLarge ? '405px' : '215px',
      lg: isLarge ? 'calc(30vw - 110px)' : '215px',
      xl: isLarge ? '405px' : '215px',
    },
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }
  const textContent = items.join(withComma ? ', ' : ' / ')
  return loading ? (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Stack
        direction={'row'}
        sx={{ justifyContent: 'space-between', flexGrow: 1 }}
      >
        <Skeleton variant={'text'}>
          <Typography sx={{ mr: '4px' }}>{label}</Typography>
        </Skeleton>
        <Skeleton variant={'text'} width={'60%'} />
      </Stack>
    </Box>
  ) : (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Stack
        direction={'row'}
        sx={{ justifyContent: 'space-between', flexGrow: 1 }}
      >
        <Typography
          variant={isLarge ? 'body1' : 'body2'}
          sx={{ color: 'text.secondary', mr: '4px' }}
        >
          {label}
        </Typography>

        {valueLoading ? (
          <Skeleton variant={'text'} width={'60%'} />
        ) : (
          <TooltipForEllipsis
            title={textContent}
            variant={isLarge ? 'body1' : 'body2'}
            sx={isItemsEllipsis ? ellipsisStyles : {}}
            textTransform={capitalizeItems ? 'capitalize' : 'none'}
          >
            {textContent}
          </TooltipForEllipsis>
        )}
      </Stack>
      {withInfo && (
        <Box sx={{ ml: '8px', display: 'inline-flex' }}>
          <InfoButton
            title={infoTitle || 'Conditions'}
            noticeText={
              infoText || (
                <>
                  Please see the seller listing for full details and visit our{' '}
                  <a
                    target='_blank'
                    href='https://help.bidsheet.io/article/56-lot-conditions-explained'
                    rel='noreferrer'
                  >
                    Knowledge Base
                  </a>{' '}
                  for a detailed description of each condition.<br></br>
                  <br></br>
                  <strong>New</strong>
                  <br></br> The items in this lot are brand-new, unused,
                  unopened, and undamaged in their original packaging.<br></br>
                  <br></br>
                  <strong>Open-Box</strong>
                  <br></br>The items in this lot are in excellent, new condition
                  with no wear.<br></br>
                  <br></br>
                  <strong>Refurbished - Grade A</strong>
                  <br></br>The items in this lot are in like-new condition. It
                  has been professionally refurbished, inspected, and cleaned.
                  <br></br>
                  <br></br>
                  <strong>Refurbished - Grade B</strong>
                  <br></br>The items in this lot show minimal wear. They are
                  fully functional and have been professionally refurbished,
                  inspected, and cleaned.<br></br>
                  <br></br>
                  <strong>Refurbished - Grade C</strong>
                  <br></br>The items in this lot show moderate wear. They are
                  fully functional and have been professionally refurbished,
                  inspected, and cleaned.<br></br>
                  <br></br>
                  <strong>Refurbished - Mixed Grade</strong>
                  <br></br>The items in this lot can be in multiple wear levels,
                  but they are fully functional and have been professionally
                  refurbished<br></br>
                  <br></br>
                  <strong>Used</strong>
                  <br></br>The items in this lot were previously used and not
                  refurbished by the seller.<br></br>
                  <br></br>
                  <strong>For parts</strong>
                  <br></br>The items in this lot do not function as intended and
                  are not fully operational.
                </>
              )
            }
          />
        </Box>
      )}
    </Box>
  )
}
