import { Stack, Typography } from '@mui/material'
import { CircledClearIcon, CircledTickIcon } from 'icons'
import React, { FC } from 'react'

import { Props } from './BidLabel.types'

export const BidLabel: FC<Props> = ({ isHighest }) => {
  return (
    <Stack
      direction='row'
      sx={{
        alignItems: 'center',
      }}
    >
      {isHighest ? (
        <CircledTickIcon width={24} height={24} color='#009816' />
      ) : (
        <CircledClearIcon width={24} height={24} color='rgba(245, 39, 26, 1)' />
      )}

      <Typography
        variant='body2'
        sx={{
          color: isHighest ? 'primary.light' : 'error.main',
          fontWeight: 600,
          ml: '6px',
          textTransform: 'uppercase',
        }}
      >
        {isHighest ? 'Highest Bid' : 'Outbid'}
      </Typography>
    </Stack>
  )
}
