import { Typography } from '@mui/material'
import React, { FC } from 'react'
import { renderStatusStyles } from 'utils/auctionStatus'

import { Props } from './AuctionStatus.types'

export const AuctionStatus: FC<Props> = ({ title, fontSize = '18px' }) => {
  return (
    <Typography
      sx={{
        fontSize: { fontSize },
        lineHeight: '24px',
        textTransform: 'uppercase',
        fontWeight: 600,
        ...renderStatusStyles(title),
      }}
    >
      {title}
    </Typography>
  )
}
