import { Box, IconButton, Link, Typography } from '@mui/material'
import { ModalCustom } from 'components/ModalCustom'
import { InfoIcon } from 'icons'
import { FC, useState } from 'react'

import { Props } from './InfoButton.types'
export const InfoButton: FC<Props> = ({
  iconColor = 'rgba(34, 34, 34, 1)',
  iconSize = 20,
  isLink,
  noticeText = 'You can notify or tell user about something this way',
  title = 'Information',
}) => {
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  return isLink ? (
    <Box sx={{ height: '20px' }}>
      <Link rel='noopener noreferrer' href={isLink} target={'_blank'}>
        <InfoIcon
          width={iconSize}
          height={iconSize}
          viewBox='0 0 24 24'
          color={iconColor}
        />
      </Link>
    </Box>
  ) : (
    <>
      {' '}
      <IconButton
        onClick={handleClickOpen}
        sx={{
          minWidth: '16px',
          cursor: 'pointer',
          padding: '0',
          '&:hover': {
            backgroundColor: 'transparent',
          },
        }}
      >
        <InfoIcon
          width={iconSize}
          height={iconSize}
          viewBox='0 0 24 24'
          color={iconColor}
        />
      </IconButton>
      <ModalCustom
        onCloseClick={handleClose}
        open={open}
        isCancelButton={false}
        title={title}
        acceptText={'OK'}
        onAcceptClick={handleClose}
      >
        <Typography>{noticeText}</Typography>
      </ModalCustom>
    </>
  )
}
