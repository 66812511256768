import { Box, Typography } from '@mui/material'
import { LockIcon, LockOpenIcon } from 'icons'
import React, { FC } from 'react'
import { formatCurrency } from 'utils/format'

import { Props } from './PriceStatus.types'

export const PriceStatus: FC<Props> = ({
  reservePrice,
  showReservePrice = false,
  showIcon = false,
  hasReservePrice,
  isReservePriceMet,
}) => {
  const getTitle = (): string => {
    if (!hasReservePrice) {
      return 'No reserve price'
    }

    return showReservePrice && reservePrice
      ? `${formatCurrency(reservePrice, {
          withPrefix: true,
        })} reserve price`
      : `Reserve price ${
          hasReservePrice && isReservePriceMet ? 'met' : 'not met'
        }`
  }

  const getIcon = () => {
    if (!showIcon) {
      return null
    }

    if (!isReservePriceMet) {
      return (
        <LockIcon
          width={18}
          height={18}
          viewBox='0 0 24 24'
          color='rgba(255, 255, 255, 1)'
        />
      )
    }

    return (
      <LockOpenIcon
        width={18}
        height={18}
        viewBox='0 0 24 24'
        color='rgba(255, 255, 255, 1)'
      />
    )
  }

  return (
    <Box
      display='flex'
      alignItems='center'
      sx={{
        borderRadius: '13px',
        height: '26px',
        p: '0 8px',
        width: 'fit-content',
        color: 'white',
        bgcolor: !isReservePriceMet
          ? 'rgba(255, 92, 0, 1)'
          : 'rgba(0, 152, 22, 1)',
      }}
    >
      {getIcon()}

      <Typography
        sx={{
          fontWeight: !showIcon && !showReservePrice ? '600' : '400',
          fontSize: '14px',
          lineHeight: '26px',
          ml: '4px',
        }}
      >
        {getTitle()}
      </Typography>
    </Box>
  )
}
