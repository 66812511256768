import { Typography } from '@mui/material'
import { FC } from 'react'

import { Props } from './CornerLabel.types'

export const CornerLabel: FC<Props> = ({ title }) => {
  return (
    <Typography
      sx={{
        borderRadius: '8px 0',
        height: '20px',
        p: '0 8px',
        fontWeight: 600,
        fontSize: '13px',
        width: 'fit-content',
        bgcolor: 'primary.main',
        color: 'white',
        whiteSpace: 'nowrap',
        textTransform: 'uppercase',
        position: 'absolute',
        top: '0',
        left: '0',
      }}
    >
      {title}
    </Typography>
  )
}
